<script>
	import { ref, /*onMounted, toRef*/  } from 'vue';
  import { loadScript } from '@paypal/paypal-js';
  import { checkShippingAddress, generateUniqueKey} from "@/DataModel/JsFunctions";
  import { ProductionsDB } from "@/DataModel/ProductDBDataStruct";
  import { ShippingData, shippins } from "@/DataModel/ShippingData";
  import {CartItem, store} from '../DataModel/CartItemDB'
  import {AddressBook} from '../DataModel/AddressBook'
  import { SendAjaxRequest, EmailSignature } from "@/DataModel/JsFunctions";
  import { ProductsDBInstance } from "@/DataModel/ProductDBDataStruct";
  //import { ShoppingCartOutlined} from '@ant-design/icons-vue';
  //import { setupPaypal } from "@/DataModel/paypalhandler";


	export default {
		name: 'ShoppingCardCheckout',
    props: {
      msg: String,
      id: {
      type: String,
      required: false
    }
    },
		
    emits: ['resetUserInput', 'openGTCorRWDEvent'],
    data() {
     return {
      
      pause_pay_func:ref(false),

      detail_shoppingcard_visable:ref(false),
      user_agree_cond: ref(false),
      CartItem:null,   //cart item template
      duplcated_index: 0,   //save a global duplcated item index
      shoppingcart_img_width: 100,
      dummy_paypal: ref(true),


      name1: '',
      name2: '',
      name3: '',
      street: '',
      housenr: '',
      region: '',
      postcode: '',
      selectedCountry: '',
      email: '',
      phone: '',
      pay_shippingaddress: [],
      payAmount: 0,
      paypalInitialized: false, // Zustand für die Initialisierung von PayPal

      countries : [],


      /********************/
      items: [],
       shipplinglist: [],
       //shippins: new ShippingData(),
       shippingCost: 0,
       shippingWaySelected:'',
       totalPrice: 0,
       leadTimeDay: 0,
       TotalWeight: 0,
       disable_select_shipping: ref(false),
       disable_address_input: ref(true),
       //productions: new Productions(),
       productionsDB: new ProductionsDB(),
       open_info_wnd: false,
       tmp_product: { C_A:'', C_B:'', CAB:'', C_L:'', Product:'', Quantity: 0, Sub_Totl: 0, Weight: 0, Lead_Time: 0, If_a_m: false },
       product_info: '',
       leadtime_adjust:'(Note: When ordering the same product repeatedly, the delivery time will be calculated based on the total order quantity. Please pay attention to the delivery time indicated in your shopping cart.)',

       open_msg_wnd:ref(false),
       msg_title:''
       

     };
   },

   updated(){
    //console.log("((  updated  ))")
    if (this.detail_shoppingcard_visable){
      //this.clearItemsFromShoppingCart();
      this.items.splice(0, this.items.length);
      this.reloadItemsIntoShoppingCard();
      this.LoadAddress();
    } 
   },

   watch: {
    user_agree_cond(newVal) {
      
      if  (this.pause_pay_func) return;

      this.disable_address_input = !newVal;
      if (newVal ) {
        this.dummy_paypal = !newVal;
        this.setupPaypal();
      } else {

        document.getElementById('paypal-button-container').innerHTML = '';
        this.dummy_paypal = !newVal;
      
      }
    },
    
  },

   methods:{


    async setupPaypal() {
      try {
        const paypal = await loadScript({
          //live
          'client-id': 'AT5D2oQEb3ab99K-gmHkaQoX9VX4QT5sXzX2r3oOzezClNMrln9U5TK--z6R2LytKYDj-YN73Wl6FgYH&components=buttons,funding-eligibility&currency=EUR&enable-funding=venmo,credit,card', 
          //test = sandbox
          /*
          ISBN(testing): DE61100000000081928137
          Credit (testing): 4020029166098589     08/2029 , any 3 number
          */
          // 'client-id': 'AagqWpODkcZ2UJ_DxtGcFCyZcidTOiJLdRTAe9K6FO6nzRrJ7X_RU5iQwBuSvolRYCQTJQ-6Deawb0dp&components=buttons,funding-eligibility&currency=EUR&enable-funding=venmo,credit,card',
        });



        paypal.Buttons({
          onClick: () => {
            const tmp1 = [{
              name1: this.name1,
              name2: this.name2,
              name3: this.name3,
              street: this.street,
              housenr: this.housenr,
              region: this.region,
              postcode: this.postcode,
              country: this.selectedCountry,
              email: this.email,
              phone: this.phone,
            }];


             //check if shopping cart empty
            
            if (this.$store.getters.cartItems.length == 0){
                 this.handlePaymentEvent(4);
                  return false;
               }

            //check shipping way
            //console.log("shipping cost : "+this.$store.getters.shippingCost);
             if (this.$store.getters.shippingCost === 0 ){
                this.handlePaymentEvent(3);
                  return false;
              }

            
            //check shipping address
            const ret = checkShippingAddress(tmp1);
            if (ret !== "-") {
              //this.$emit('onPaymentEvent', ret); // Berichten Sie über die ungültige Benutzereingabe
              this.handlePaymentEvent(ret);
              return false;
            }


            //check the shipping way&cost and country again
            if (this.handleEventPaymentCondition("2:"+this.selectedCountry) == -1){
              return false;
            }

            return true;
          },
          createOrder: (data, actions) => {

            const countryCode = ShippingData.getCountryCode(this.selectedCountry);
          

            const shippingAddress = {
              name1: this.name1,
              name2: this.name2,
              name3: this.name3,
              street: this.street,
              housenr: this.housenr,
              region: this.region,
              postcode: this.postcode,
              country: this.selectedCountry,
              email: this.email,
              phone: this.phone,
            };
            this.pay_shippingaddress.push(shippingAddress);

            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    value: this.$store.getters.cartSubtotal,
                  },

                  shipping: {

                    name: {
                        full_name: this.name1 + " " + this.name2 + " " + this.name3
                    },

                    address: {
                      address_line_1: this.street,
                      address_line_2: this.housenr,
                      admin_area_1: "-",
                      admin_area_2: this.region,
                      postal_code: this.postcode,
                      country_code: "DE" //countryCode,//this.selectedCountry,
                    }
                  } 
                },
              ],

              payer: {
                name: {
                  given_name: this.name1,
                  surname: this.name2
                },
                email_address: this.email,
                phone: {
                
                  phone_type: "MOBILE",
                  phone_number: {
                    country_code: "43", 
                    national_number: this.phone
                      
                  },
                },
                address: {
                  address_line_1: this.street,
                  address_line_2: this.housenr,
                  admin_area_1: this.region,
                  admin_area_2: "-",
                  postal_code: this.postcode,
                  country_code: countryCode//this.selectedCountry
                }
              }

            });
          },
          onApprove: (data, actions) => {
            return actions.order.capture().then(() => {
              //this.$emit('onPaymentEvent', generateUniqueKey()); // Zahlung erfolgreich
              this.handlePaymentEvent(generateUniqueKey());
            });
          },
          onError: (err) => {
            console.log(err);
            this.handlePaymentEvent(2);
            //this.$emit('onPaymentEvent', 2); // Zahlung mit Fehler abgebrochen
          },
          /*style: {  //credit button does not support horizontal 
            layout: 'horizontal',
            color: 'gold',
            shape: 'rect',
            label: 'paypal'
          },*/

          funding: {
            allowed: [paypal.FUNDING.CARD, paypal.FUNDING.VENMO, paypal.FUNDING.CREDIT],
            disallowed: []
         },

        }).render('#paypal-button-container');

        this.paypalInitialized = true; // Markiere PayPal als initialisiert
      
      } catch (error) {
        //console.error(error);
      }
    },

    show_hide_detail_shoppingcard(){


      if (this.pause_pay_func){
       
       this.msg_title="System Upgrade Notice -1";
       this.atten_msg= "Thank you for using our service. Due to a system upgrade, the online payment function is temporarily unavailable. "+
                       "It is expected that this feature will be available again in mid-September.\n"+
                       "Thank you for your attention.";
       this.open_msg_wnd = true;

     }


      //force the buyer to read the GTC and Return withdraw again
      this.detail_shoppingcard_visable = !this.detail_shoppingcard_visable
      this.shoppingcart_img_width = (this.shoppingcart_img_width === 100) ? 50 : 100;
      if (!this.detail_shoppingcard_visable){
        //this.$store.dispatch("setReadGTS_RW", false);
        this.user_agree_cond  = false;
      }
     },

     agree_GTC_RW(){

      //pay function is not available due to 1.) update 2.) holiday
      if (this.pause_pay_func){
       
        this.msg_title="System Upgrade Notice -2";
       this.atten_msg= "Thank you for using our service. Due to a system upgrade, the online payment function is temporarily unavailable. "+
                       "It is expected that this feature will be available again in mid-September.\n"+
                       "Thank you for your attention.";
        this.open_msg_wnd = true;

        return;
      }

      
      this.user_agree_cond =!this.user_agree_cond;
      
      this.$store.dispatch("setReadGTS_RW", !this.user_agree_cond);

      if (this.user_agree_cond) {
        this.$nextTick(() => {
          const paypalButtonContainer = document.getElementById('check_button_area');
          if (paypalButtonContainer) {
            paypalButtonContainer.scrollIntoView({ behavior: 'smooth' });
          }
        });
      }


     },

     reloadItemsIntoShoppingCard(){
        //console.log(" .... loading.......")
        this.$store.state.cartItems.forEach((item)=>{
            //this.items.push[{Index: index,Product:item.prod_name, Quantity:item.prod_quan, Sub_Totl: item.sub_Total}]
            this.items.push({Product:item.prod_name, Quantity:item.prod_quan, Sub_Totl: item.prod_subtotal, Lead_Time: item.prod_leadtm, If_a_m: item.is_standard})
          }
         
        )
        
       if (this.$store.getters.shippingCost > 0){
          this.shippingWaySelected = shippins.findShippingWayByValue(this.$store.getters.shippingCost);
       }

      },
     clearItemsFromShoppingCart(){
        this.$store.getters.cartItems.splice(0, this.items.length);
        this.$store.dispatch("setShippingCost", 0.00);
        AddressBook.dispatch("clearAddress"); 
      },

   
     handleEventPaymentCondition(whattod){

        var substrings = whattod.split(":");
        //add more products
        if (parseInt(substrings[0]) === 1){
         
          this.user_agree_cond = false;
          this.detail_shoppingcard_visable = false;
        }

        //check if country is germany or not and info to change the shipping method
        if (parseInt(substrings[0]) === 2){
          
          const right = 1;
          const error = -1;
          //find the available shipping cost
          let shippingcost =  parseFloat(this.$store.getters.shippingCost);
          let tmp_shp_cost = 0;
          //if (this.debug === 1) {
          //  tmp_shp_cost =  shippins.findAvailableShippingCostLocal(this.$store.getters.totalWeight, substrings[1] );
          //}
          //else{
          //console.log("----- parameters----" + this.$store.getters.totalWeight +  " Kg  , "+  substrings[1]); 
          tmp_shp_cost = shippins.findAvailableShippingCost(this.$store.getters.totalWeight, substrings[1] );
           
          //}
          //this.$refs.ShoppingCartTable.updatShippingCost(tmp_shp_cost); 
          this.$store.dispatch("setShippingCost", parseFloat(tmp_shp_cost,2));
          
          if (shippingcost !==tmp_shp_cost){
              this.msg_title="Shipping rate changed";
              this.atten_msg= "Please be aware that we have adjusted the shipping method and its associated costs based on your selected destination country and the weight of the products.";
              this.shippingCost = tmp_shp_cost;
              this.open_msg_wnd = true;
              return error;
          }
          return right;
        }

        //save the changed address
        if (parseInt(substrings[0]) === 4){
          var subaddress = substrings[1].split("§");
          shippins._Address = [...subaddress.slice(0, 10)];
        }

        return 0;
   },

   handlePaymentEvent(whattodo){
      
      ////console.log( whattodo+ "..............." + whattodo);
      if (whattodo.length == 13 && (whattodo.indexOf("-") !== -1)){
        this.msg_title = "Payment successful";
        this.atten_msg= "Thank you for your Order ! \n\n" +
                         "Order-ID: " + whattodo + "\n\n" + 
                         "(If you haven't received the order confirmation email in a few minutes, \n" +
                         "please send us an email at contact@h-2technik.com and include this order number.)";
        this.open_msg_wnd = true;
        
        //2024.05.26
        //update the inventory on server side
        //ProductsDBInstance.updateInventory(this.$refs.ShoppingCartTable.getItems());
        ProductsDBInstance.updateInventory(this.$store.getters.cartItems);
      
        //send mail to customer and BCC to contact@h-2technik
        this.sendMail(AddressBook.getters.address.Email + "/" + "contact@h-2technik.com",
                      "Your Order in detail by h-2technik" + " (Order-ID:" + whattodo + ")", 
                      "Dear Mrs./Miss/Mr. " + AddressBook.getters.address.Name1+" " +
                                              AddressBook.getters.address.Name2+" " + 
                                              AddressBook.getters.address.Name3 + "\n\n Thank you for your order. The details of your order are as follows: \n\n" +
                                             "******************************************** \n" + 
                                             this.$store.getters.getAllProductsInStr + 
                                             "\n shipping address: ["+ 
                                             AddressBook.getters.address.Name1 + " " +AddressBook.getters.address.Name2 + " " + AddressBook.getters.address.Name3 + ", " +
                                             AddressBook.getters.address.Street + " " +AddressBook.getters.address.HouseNr + "," +
                                             AddressBook.getters.address.PostCode + " " +AddressBook.getters.address.Region + "," +
                                             this.selectedCountry+ ", " + 
                                             "Tel: " + AddressBook.getters.address.Phone+ "]\n" +
                                             "====>\n" + "******************************************** \n" + 
                                             EmailSignature());

        //reset shoppingcart table
        //this.$refs.ShoppingCartTable.resetTable();
        
        this.clearItemsFromShoppingCart();
        this.shippingWaySelected = null;
        this.user_agree_cond = false;
        this.shoppingcart_img_width = 100;  //resize the logo 
        this.detail_shoppingcard_visable = false;
      }
      //Erro payment is broken 
      else if (whattodo === 2){
        this.msg_title = "Error";
        this.atten_msg= "An error occurred during the payment process, and the payment has been terminated.";
        this.open_msg_wnd = true;
      }
      //not shipping way selection
      else if(whattodo === 3)
      {
        this.msg_title = "Error";
        this.atten_msg= "Please select the shipping method before processing payment.";
        this.open_msg_wnd = true;
        this.$refs.shippingway_select.focus();
      }
      else if(whattodo === 4)
      {
        this.msg_title = "Error";
        this.atten_msg= "Shopping cart is empty. Please select products and add them to the shopping cart..";
        this.open_msg_wnd = true;
      }
      //shipping address error
      else if (whattodo.length >=5){
        this.msg_title = "Invalide Shipping Address !";
        this.atten_msg= whattodo.substring(1);
        this.open_msg_wnd = true;
      }

      ////console.log( "++++++++++" + whattodo + "..............." + this.$refs.PaypalBlock.pay_shippingaddress[0].name1);
    },

    sendMail(mails, subject, contents){
      //console.log( mails );
      const data = {
        adds: mails, 
        subj: subject, 
        body: contents,
      };
      
      SendAjaxRequest(
        data,
        () => {
          // Erfolgsfall
          this.uemail = "";
          this.uphone = "";
          this.usubject = "";
          this.uvalue = "";
          //console.log("O.K. sending email");
        },
        () => {
          // Fehlerfall
          //console.log("error sending email");
        }
      );
    },

   
      AddMore(){
        //this.$emit('EventPaymentCondition', "1:"); //add more products
        this.handleEventPaymentCondition("1:");
      },

      changeCountry(){
        //this.handleInputChange(this.selectedCountry, "Country");
        //this.$emit('EventPaymentCondition', "2:"+this.selectedCountry); //check if selected country is germany or not
        this.handleEventPaymentCondition("2:"+this.selectedCountry);
      },
      
      //not implemented yet
      Preorder(){
        //this.$emit('EventPaymentCondition', "3:"); //add more products
        this.handleEventPaymentCondition("3:");
      },

      LoadAddress(){
         this.name1 = AddressBook.getters.address.Name1;
         this.name2 = AddressBook.getters.address.Name2;
         this.name3 = AddressBook.getters.address.Name3;
         this.street = AddressBook.getters.address.Street;
         this.housenr= AddressBook.getters.address.HouseNr;
         this.region = AddressBook.getters.address.Region;
         this.postcode = AddressBook.getters.address.PostCode;
         if (AddressBook.getters.address.Country.length > 0){
          this.selectedCountry = AddressBook.getters.address.Country;
          this.changeCountry();
         }
         this.email = AddressBook.getters.address.Email;
         this.phone = AddressBook.getters.address.Phone;
      },
       
      //address being changed, save values
      handleInputChange(val, fieldname){
        AddressBook.dispatch("updateAddressField", {field: fieldname, value:val.trim()});
        //console.log(AddressBook.getters.address);
        var addressstr = this.name1 +"§"+this.name2 +"§"+this.name3 +"§"+this.street +"§"+this.housenr +"§"
                        +this.region +"§"+this.postcode +"§"+this.selectedCountry +"§"+this.email +"§"+this.phone+"§§";
        //this.$emit('EventPaymentCondition', "4:" + addressstr); //add more products
        this.handleEventPaymentCondition("4:" + addressstr);
      },

      /***************************************************************************************************************************/
      focusingShippingWay(){
      return this.$refs.shippingway_select.focus();
      },

		addProductInShoppingCart( newItem ) {
      
      
      //console.log(newItem);
      let b = this.ifDuplicateProducts(newItem);
			//determin if products is already added
        if (!b){
             //this.gettotalPrice();
             this.updateLeadTime();
             //this.updateTotalWeight();
     
          }
        return b;
      },

		ifDuplicateProducts(newItem){
			var b1 = false;
      var i1 = -1 ;

      var i2= -1;
      var b2 = false;
      
      //正向查找
      store.getters.cartItems.forEach((item, inx)=>{
      
        //this.items.forEach((item, inx) => {
            if ((item.conn_A === newItem.C_A) && (item.conn_B === newItem.C_B) && 
               (item.cable_type === newItem.CAB) && (item.cable_length === newItem.C_L)){
                 b1 = true;
                 i1 = inx;
              }
            });
           
         //反向查找 
        if (!b1){   
           store.getters.cartItems.forEach((item, inx)=>{
           //this.items.forEach((item, inx) => {
           if ((item.conn_A === newItem.C_B) && (item.conn_B === newItem.C_A) && 
               (item.cable_type === newItem.CAB) && (item.cable_length === newItem.C_L)){
                b2 = true;
                i2 = inx;
             }
           });
         }
           //product is existing
           if ( (b1 || b2) ){
             var ii = Math.abs(i1) * Math.abs(i2);
             this.tmp_product = newItem;
             this.product_info = "The product already exists (Item=" +  (++ii) + "). Do you want to confirm the addition? ";
             this.duplcated_index = ii;
             //console.log("i1 = " + i1 + "... i2=" + i2  + "....... i1*i2=" + i1*i2);
             this.open_info_wnd = true;

            }
           else{
            //this.items.push(newItem);
            //26.05.2024   organize new CartItem and push it into global store
            const tmp_cartitem = new CartItem((newItem.If_a_m)?2:1, newItem.If_a_m, newItem.Product, newItem.Quantity, newItem.Sub_Totl, newItem.Weight, 
                                               newItem.Lead_Time, "","","", newItem.C_A, newItem.C_B, newItem.CAB, newItem.C_L);
            
            this.$store.dispatch("addNewItem", tmp_cartitem);

           }
			return b1 || b2;
		},

		adjustExistingProductInfo(){
            var ii = this.duplcated_index - 1;
            
            try{
            
            store.getters.cartItems[ii].prod_subtotal = (parseFloat(store.getters.cartItems[ii].prod_subtotal) + 
                                                          parseFloat(this.tmp_product.Sub_Totl)).toFixed(2);
            store.getters.cartItems[ii].prod_quan   += this.tmp_product.Quantity;
            //this.items[ii].Lead_Time = this.productions.LeadTimeInDay(this.tmp_product.C_A, this.tmp_product.C_B, this.tmp_product.CAB, this.tmp_product.C_L, this.items[ii].Quantity);
            
            //console.log("....total quantity..." + store.getters.cartItems[ii].prod_quan);

            const self = this;
            this.productionsDB.performTask(9, this.tmp_product.C_A, this.tmp_product.C_B, this.tmp_product.CAB, 0, store.getters.cartItems[ii].prod_quan)
                                                        .then(function(result){
                                                            var data = JSON.parse(result);
                                                            //self.items[ii].Lead_Time = data.values[0].val;
                                                            store.getters.cartItems[ii].prod_leadtm = data.values[0].val;
                                                            //console.log("new calculated leadtime from db:" + data.values[0].val);
                                                         })
                                                         .catch(function (error){
                                                            console.log("Error cal. time:" + error);
                                                            self.time = 0;
                                                        });

           

          }catch(e){
            console.log(" ... duplicate products Error....." + e);  
          }

          setTimeout(() => { 
            //this.gettotalPrice();
            this.updateLeadTime();
            //this.updateTotalWeight();
          },
          1000); 

          

		},
        
		deleteItem(index) {
      
      /**remove item from global storage**/
      //console.log(index+ "...item is to be deleted")
      this.$store.dispatch("removeItemByIndex", index);
      if (this.$store.getters.cartItems.length == 0) {
        //console.log("no items more");
        this.shippingWaySelected = null;
      }
      /**********************************/
      this.items.splice(index, 1);

      //after remove, the country in shipping address block must be reset
       //this.selectedCountry = null;
     
		},
      
    ToggleShippingSelection(val){
       //console.log(" shipping dis-/enable: " + val);
        this.disable_select_shipping = val;
    },
    
    updatShippingCost(value) {
        this.shippingCost = value;
        this.$store.dispatch("setShippingCost", parseFloat(value,2));
        //console.log("shipping cost = " + this.$store.getters.shippingCost)
        this.gettotalPrice();
    },
      
    gettotalPrice( ){
  
		
    },

    getItemCnt(){
       return this.items.length;
    },

    getItems(){
       return this.items;
    },

    getAllProductsInStr(){
     let all_prd_str = "";

		this.items.forEach((item, inx) => {
           all_prd_str += (++inx) + ' ====> Name: ' + item.Product + ' Quantity: ' + item.Quantity + '\n';
        });
    all_prd_str += "********************************************\n"    
		all_prd_str += "Shipping Way:" + this.shippins.findShippingWayByValue(this.shippingCost) + "\n"
    all_prd_str += "Shipping Cost:" + this.shippingCost + "€ \n"
		all_prd_str += "********************************************\n"
		all_prd_str += "*  Payed Total Price(€):" + this.totalPrice + "* \n"
		all_prd_str +="********************************************* \n" +
                      "*  Overall Lead Time (Day):" + this.leadTimeDay + "* \n" +
                      "********************************************* \n";

        //console.log('All Products: \n' + all_prd_str);
		return all_prd_str;
    },

   updateLeadTime(){
    },

    updateTotalWeight(){
     let weight = 0;
     this.items.forEach((item) => {
       weight += parseFloat(item.Weight);
       //console.log("weight=" + weight + "...." + item.Weight);
     })
     this.TotalWeight = parseFloat(weight).toFixed(3);
    },
    
    resetTable(){
		this.items.splice(0);
		this.totalPrice = 0;
		this.shippingCost='0.00';
    this.TotalWeight = '0.000';
    this.leadTimeDay = 0;
    this.ToggleShippingSelection(false);
    },

    handleAddDuplicatedProduct(){
		this.open_info_wnd = false; 
        this.adjustExistingProductInfo();
        this.$emit('resetUserInput');

    },
    
    handleCancelEdit(){
       this.open_info_wnd = false;
       this.duplcated_index = 0;
       this.$emit('reEditUserInput');
    },

    /*new methods*************************************************************************************************/
    parent_openGTCorRW(whattodo){
      this.$emit("openGTCorRWDEvent", whattodo)
    },

    
    closeMessageWnd(){
    this.open_msg_wnd = false;

    },

   },

  
   
   mounted() {

   
    /******************************************************************/
    const self = this;
      
      //eu countries
      shippins.fetchData(5, "")
                          .then(function(result){
                            shippins._EU_COUNTRIES = JSON.parse(result);    
                            self.countries.push(...shippins._EU_COUNTRIES);
                            //console.log("countries: " + result) ; 
                          })
                          .catch(function (){
                             shippins._EU_COUNTRIES = ShippingData.EU_COUNTRIES;  
                             self.countries = ShippingData.EU_COUNTRIES;
                             //console.log("1---") ;   
                          });
      
      //Non-EU countries
      shippins.fetchData(6, "")
                          .then(function(result){
                             shippins._NON_EU = JSON.parse(result);  
                             self.countries.push(...shippins._NON_EU);
                             //console.log("2---V") ; 
                          })
                          .catch(function (){
                             shippins._NON_EU = ShippingData.NON_EU;   
                          });

      //shipping ways & pricie 
      shippins.fetchData(7, "")
                          .then(function(result){
                            self.shipplinglist = JSON.parse(result);   
                            shippins._SHIPPING_DHL = JSON.parse(result); 
                            //console.log("1:" + shippins._SHIPPING_DHL);   
                          })
                          .catch(function (){
                            self.shipplinglist = ShippingData.SHIPPING_DHL;   
                            shippins._SHIPPING_DHL = ShippingData.SHIPPING_DHL;
                            //console.log("2:" + shippins._SHIPPING_DHL); 
                          });

   },
   
};
</script>

<template>
  
 
 <a-badge :count="$store.getters.cartItems.length" @click="show_hide_detail_shoppingcard">
       <a-image :width="shoppingcart_img_width" src="./assets/others/shoppingcart.png"  :preview="false"/>             
            <a href="#" class="head-example" />
 </a-badge>


<div class="space-align-block" v-if="detail_shoppingcard_visable">

    <a-space direction="vertical" align="center" size="large">
       

  <div class="product-table-container" >
	<table class="product-table">
		<thead>
			<tr>
				<th width="5%">Item</th>
				<th>Product</th>
				<th width="4%">Quantity</th>
				<th width="15%">Sub-Total(€)</th>
				<th>Action</th>
			</tr>
		</thead>
		<tbody>		
			
			<tr v-for="(item, index) in items" :key="index">
             <!--td>{{ item.Item }}</td-->
             <td><p style="font-size: small;">{{ index+1 }}</p></td>
             <td><p style="font-size: small; font-weight: bold;">{{ item.Product }}</p></td>
             <td><p style="font-size: small;">{{ item.Quantity }}</p></td>
             <td><p style="font-size: small;">{{ item.Sub_Totl }}</p></td>
             <td><a-button danger style="display: flex;   align-items: center; justify-content: center;" type="text" @click="deleteItem(index)">
                       <a-image src="./assets/others/trash.png" alt="Remove" :preview="false"/>
                  </a-button>
            </td>
          </tr>
			
			<tr>
				<td colspan="3" style="text-align:left;"><b>Shipping Way</b> &nbsp;&nbsp;&nbsp;
         <a-space>
         <a-select id="shippingSelect" v-model:value="shippingWaySelected" @change="updatShippingCost" :options="shipplinglist" style="width:290px" :disabled="disable_select_shipping?true:false" ref="shippingway_select">
          </a-select>
          <!--<p style="text-align: center;">(Estimated total weight: {{ TotalWeight }} Kg)</p>-->
          <!--<p style="text-align: center;">(Estimated total weight: {{this.$store.getters.totalWeight}} Kg)</p>-->
          <label>(Estimated total weight: {{this.$store.getters.totalWeight}} Kg)</label>
         </a-space> 
          <p style="font-size:15px; color:red">*The shipping way is automatically adjusted based on destination country (in the Check-Out block) and the weight of the goods.</p>
				</td>
				<td id="realShippingCost" style="font-size:15px;font-weight: bold; vertical-align: top;">{{this.$store.getters.shippingCost}}</td>
				<td></td>
			</tr>
								
			<tr>
				<td colspan="3" style="text-align:right">
					<b><label for="shippingSelect">Total (with 19% VAT)</label></b>
				</td>
				<!--<td id="totalPrice"><b>{{totalPrice}}</b></td>-->
        <td id="totalPrice"><b>{{ this.$store.getters.cartSubtotal}}</b></td>
				<td>
          <!--<a-space direction="horizontal">
           <a-button type="primary" @click="AddMore">Modify Products</a-button>
         </a-space>-->
        </td>
			</tr>
			<tr>
				<td colspan="3" style="text-align:right">
					<b><label for="leadtimeDay">Overall Lead Time(Day)</label></b>
				</td>
				<td id="leadTimeDay">{{this.$store.getters.getLeadTime}}</td>
				<td></td>
			</tr>							
		</tbody>
	</table>
   
 </div>
 </a-space>
</div>

<!--**********************************************************************************************************************+-->
<div class="space-align-block" v-if="detail_shoppingcard_visable" id="check_button_area" >
      <a-space direction="vertical" align="center" size="small" >
          <label style="font-weight: bold;" >Shipping Address/Check Out</label>
        
           <a-checkbox v-model:checked="user_agree_cond" id="ShoppingCardID" @click="agree_GTC_RW">  
            I have read the <a href="#" @click="parent_openGTCorRW(1)">General Terms and Conditions</a> and <a href="#" @click="parent_openGTCorRW(2)">Right of Withdrawal.</a>
          </a-checkbox> 
   

      <a-space direction="vertical" align="start">
       <a-space direction="horizontal" >
        <a-input v-model:value="name1" size="small" placeholder="Name 1*" @change="handleInputChange(name1, 'Name1')" :disabled='disable_address_input' />
        <a-input v-model:value="name2" size="small" placeholder="Name 2*" @change="handleInputChange(name2, 'Name2' )" :disabled='disable_address_input'/>
        <a-input v-model:value="name3" size="small" placeholder="Name 3" @change="handleInputChange(name3, 'Name3')" :disabled='disable_address_input'/>
       </a-space>

       <a-space direction="horizontal">
        <a-input v-model:value="street" style="width:300px" size="small" placeholder="Street *" @change="handleInputChange(street, 'Street')" :disabled='disable_address_input'/>
        <a-input v-model:value="housenr" size="small" placeholder="Haus/Room Nr.*" @change="handleInputChange(housenr, 'HouseNr')" :disabled='disable_address_input'/>
      
       </a-space>

       <a-space direction="horizontal">
        <a-input v-model:value="postcode" size="small" placeholder="Post Code*" @change="handleInputChange(postcode, 'PostCode')" :disabled='disable_address_input'/>
        <a-input v-model:value="region" size="small" placeholder="Region/City*" @change="handleInputChange(region, 'Region' )" :disabled='disable_address_input'/> 
       </a-space>
       
       <a-space-compact>
          <a-input  value="country*" style="width: 44%;" size="small" :disabled="true"/>
          <a-select v-model:value="selectedCountry" style="width:193px" size="small"  @change="changeCountry" :disabled='disable_address_input'>
          
           <a-select-option v-for="option in countries" :key="option.value" :value="option.label" :disabled='disable_address_input'>
              {{ option.label }}
           </a-select-option>
          </a-select>
        </a-space-compact>

       <a-space direction="horizontal">
         <a-input v-model:value="email" size="small" style= "width:230px" placeholder="Email*" @change="handleInputChange(email, 'Email')" :disabled='disable_address_input'/>
         <a-input v-model:value="phone" size="small" placeholder="Mobile*" @change="handleInputChange(phone, 'Phone' )" :disabled='disable_address_input'/>
       </a-space>

       <a-space direction="horizontal" style="width:188px"> 
          <div v-if="user_agree_cond" id="paypal-button-container">
          </div>
          
          <div v-if="dummy_paypal">
            <a-button style="height:40px; width:136px; background-size:cover; background-image:url('./assets/others/dummy_btn_paypal.png');"></a-button>
          </div>
        </a-space>

    </a-space>
  </a-space>
</div>

<a-modal v-model:open="open_info_wnd" title="Duplicate Product" @ok="handleAddDuplicatedProduct" @cancel="handleCancelEdit">
		<p style="color:red"> {{ product_info }}  <br> {{ leadtime_adjust }}  </p>
</a-modal>

<a-modal v-model:open="open_msg_wnd" :title="msg_title" @ok="closeMessageWnd" >
      <span style="white-space: pre-line;">{{ atten_msg }}</span>
      <!--p>{{ atten_msg }}</p-->
</a-modal>
   
</template>

<style scoped>

.space-align-block {
  margin: 3px 3px;
  border: 1px solid #7cb305;
  border-radius: 8px;
  padding: 4px;
  flex: none;
  height: 300px;
  overflow: auto; 
  width: 99%;
}
.space-align-block .mock-block {
  display: inline-block;
  text-align: left;
  padding: 2px 2px 2px 2px;
  margin-right: 5px;
  border-right: 1px solid #0000ff;
  color: gray
  /*background: rgba(150, 150, 150, 0.2);*/
}


/*************************************************************/
.product-table-container {
			display: flex;
			text-align: center;
			justify-content: center;
			align-items: center;
      flex-wrap: wrap;
		}
		

		.product-table {
    margin: 0 auto;
    width: 90%;
    border-collapse: collapse;
}

.product-table th {
    padding: 5px; /* Zum Hinzufügen von Abstand um den Zellinhalt in Header-Zellen */
    border-top: 1px solid gray; /* Oberen Rahmen für Header-Zellen beibehalten */
    border-bottom: 1px solid gray; /* Unteren Rahmen für Header-Zellen beibehalten */
    text-align: center; /* Optional: Zentriere den Text in Header-Zellen */
    background-color:RGB(235,235,235);
}

/* Entferne die linken und rechten Rahmen in der ersten und letzten Header-Zelle */
.product-table th:first-child {
    border-left: none;
}

.product-table th:last-child {
    border-right: none;
}

.product-table td {
    padding: 5px; /* Zum Hinzufügen von Abstand um den Zellinhalt in Daten-Zellen */
    border-top: 1px solid gray; /* Oberen Rahmen für Daten-Zellen beibehalten */
    border-bottom: 1px solid gray; /* Unteren Rahmen für Daten-Zellen beibehalten */
    border-left: none; /* Entferne den linken Rahmen in Daten-Zellen */
    border-right: none; /* Entferne den rechten Rahmen in Daten-Zellen */
}

.product-table  tr:nth-last-child(2){
    background-color: RGB(235,235,235);; /* Hintergrundfarbe für die letzte Zeile */
}



</style>